import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { MainHomeContainer } from './MainHomeStyle';
import MusicRouters from '../Utils/MusicRouters';
import Nav from 'react-bootstrap/Nav';
import * as Icon from 'react-bootstrap-icons';
import { BrowserRouter } from 'react-router-dom';


function BasicExample() {
  return (<MainHomeContainer>
   
    <Card style={{ width: '18rem' ,marginTop:'2%'}}>
      <Card.Img variant="top" src="https://fireimages.govindmukund.com/GateImages/crou1.JPG" />
      <Card.Body>
        <Card.Title>GateHave</Card.Title>
        <Card.Text>
        Explore our extensive range of doors and locks designed to enhance security and style for any space. From residential to commercial solutions, our products combine durability with modern design.
        </Card.Text>
        <Button variant="primary" href='https://gmonlineone.govindmukund.com/'>Go To GateHave</Button>
      </Card.Body>
    </Card>
    <Card style={{ width: '18rem' ,marginTop:'2%'}}>
      <Card.Img variant="top" src="https://fireimages.govindmukund.com/TechSol/TechSolu.jpg" />
      <Card.Body>
        <Card.Title>TechSculpt</Card.Title>
        <Card.Text>
        At TechSculpt, we specialize in crafting innovative software solutions tailored to meet the unique needs of businesses across various industries. Our mission is to empower organizations with technology that enhances efficiency, productivity, and growth.
        </Card.Text>
        <Button variant="primary" href='https://gmonlinetwo.govindmukund.com/'>Go To TechSculpt</Button>
      </Card.Body>
    </Card>
    <Card style={{ width: '18rem',marginTop:'2%' }}>
      <Card.Img variant="top" src="https://fireimages.govindmukund.com/TechSol/giftS.jfif" />
      <Card.Body>
        <Card.Title>GiftHaven</Card.Title>
        <Card.Text>
        Welcome to GiftHaven, where every occasion is celebrated with the perfect gift. Our curated collection features a wide variety of unique and thoughtful items designed to delight recipients of all ages.
        </Card.Text>
        <Button variant="primary" href='https://govindmukund.com'>Go To GiftHaven</Button>
      </Card.Body>
    </Card>
    <Card style={{ width: '82rem' ,marginTop:'2%'}} className="text-center">
      <Card.Header><Nav className="justify-content-center" activeKey="/home">
        <Nav.Item>
        <Icon.Rss color="red" />
          <Nav.Link href="/feed">Telugu_Mp4</Nav.Link>
        </Nav.Item>
        <Nav.Item>
        <Icon.Fire color="red" />
          <Nav.Link href="trending">React_Tutorial</Nav.Link>
        </Nav.Item>
        <Nav.Item>
        <Icon.CaretRightFill color="red" />
          <Nav.Link href="player">NEWS</Nav.Link>
        </Nav.Item>
        <Nav.Item>
        <Icon.HeartFill color="red" />
          <Nav.Link href="favorite">Hindi_Mp4</Nav.Link>
        </Nav.Item>
        <Nav.Item>
        <Icon.FileMusicFill color="red" />
          <Nav.Link href="/">Contact</Nav.Link>
        </Nav.Item>
      </Nav></Card.Header>
      <Card.Body>
        <BrowserRouter>
        <MusicRouters />
        </BrowserRouter>
      
      </Card.Body>
      <Card.Footer className="text-muted">Entertainment</Card.Footer>
    </Card>
    </MainHomeContainer>
    
  );
}

export default BasicExample;