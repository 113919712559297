import React from "react";

const MusicFeed =()=>{

    return(<>
     <h1>Telugu_Mp4</h1>
     <iframe width="560" height="315" src="https://www.youtube.com/embed/Wd5xLjDmet0?si=1AtbNwYrv2VNYkSi" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    </>
       
    )
}

export default MusicFeed;