import styled from "styled-components";

export const MainHomeContainer = styled.div`
// background: rgba(0, 0, 0, 0.2);
    place-content: space-between;
display: flex;
    // width: 50%;
    // height: 50%;
    padding: 5% 2% 2% 2%;
    // margin: 5% 0% 0% 25%;
    flex-flow: wrap;
`;