import axios from "axios";
import React, { useEffect, useState } from "react";


const MusicPlayer =()=>{
    const [musicPlayerData,setMusicPlayerData]=useState([]);
    const getReusult = async ()=>{
        await axios.get("https://spotify23.p.rapidapi.com/playlist/?id=37i9dQZF1DX4Wsb4d7NKfP",{headers:{'x-rapidapi-key': '1758a3f57amsh3398c1731be2b75p12f751jsnd66d57abcb99','x-rapidapi-host': 'spotify23.p.rapidapi.com'}}).then(res=>{setMusicPlayerData(res?.data?.tracks?.items);console.log(res)});
    }
useEffect(()=>{
    getReusult()

},[])

console.log(musicPlayerData)

    return(<>
     <h1>NEWS</h1>
     {/* {musicPlayerData?.map((data)=>{
       return (<span>{data?.track?.duration_ms}</span>)
     })} */}
    
    <iframe width="560" height="315" src="https://www.youtube.com/embed/Uaj08cPRlPw?si=ilhmcWSdR9BqmnLW" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" ></iframe>
    </>
   
       
    )
}

export default MusicPlayer;